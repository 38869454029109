export enum Endpoints {
  dashboardBanners = "/dashboard/banners",
  dashboardMonthlyPrize = "/dashboard/monthly-prize",
  dashboardMostPopularPrizes = "/dashboard/most-popular-prizes",
  faq = "/general/faq",
  invoiceFaq = "/general/invoice-faq",
  generalTerms = "/general/terms",
  generalPrivacy = "/general/privacy",
  generalMarketing = "/general/marketing",
  productCategories = "/product/categories",
  product = "/product",
  productCropCategories = "/product-crop-categories/products",
  favorites = "/favorite/list",
  moveToCart = "/favorite/move-to-cart",
  profileBriefInfo = "/profile/brief-info",
  profileManager = "/profile/manager",
  profileData = "/profile/",
  editProfile = "/profile/edit",
  editDistributorProfile = "/profile/distributor/edit",
  aboutProgram = "/general/about-program",
  cart = "/cart/",
  cartPrize = "/cart/prize",
  addToCart = "/cart/add",
  prizeSearch = "/prize/search",
  prize = "/prize",
  addToFavorites = "/prize/favorite",
  removeFromFavorites = "/prize/favorite",
  myInvoices = "/invoice/my-invoices",
  recentOrders = "/order/my-recent-orders", // Legacy
  orders = "/order/list",
  sendQuestion = "/contact-us/send",
  order = "/order/",
  completeOrder = "order/complete",
  prizeRecommendedPrizes = "/prize/recommended-prizes",
  addInvoice = "/invoice/upload",
  prizeFilters = "/prize/filters",
  municipality = "/municipality",
  region = "/region",
  city = "/region/city",
  crops = "/crop/",
  invoiceDescription = "/general/invoice-description",
  login = "/login-check",
  signup = "/auth/sign-up",
  signupDistributor = "/auth/distributor/sign-up",
  activateDistributor = "/auth/distributor/activate",
  resetPassword = "/auth/reset-password",
  remindPassword = "/auth/remind-password",
  confirmResetToken = "/auth/confirm-reset-token",
  voucher = "/voucher",
  landingPage = "general/landing-page",
  profileChangePassword = "/profile/change-password",
  redeemCoupon = "/voucher/redeem-coupon",
  landingHomepage = "/general/landing-page-logged-in",
  popup = "/general/pop-up",
  productsDescription = "/general/product-page-description",
  survey = "/survey",
  pointsHistory = "/point/my-points",
  loginBackgroundImage = "/general/login-background-image",
  invoiceTimer = "/countdown-timer/invoice",
  application = "/application",
  contractedTargetProgress = "/profile/contracted-product-target-progress",
  contractedProductTargetSubmit = "/user/contracted-product-target",
}

import { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import {
  AppBar,
  Grid,
  Hidden,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import Top from "./TopHeader/TopHeader";
import { useStyles } from "./Header.styles";
import Points from "./Points/Points";
import { ActivePopperType } from "../../types/Components";
import { disableBodyScroll, enableBodyScroll } from "../../utils/Common";

import { useAuth } from "../../state/Auth";
import { useProfileData } from "../../state/Profile";
import { useUtag } from "../../state/Utag";
import { useTranslation } from "react-i18next";
import ProfilePopper from "./ProfilePopper/ProfilePopper";
// import { getData } from "../../utils/LocalStorage";

const Header = ({ growMenu }: { growMenu: boolean }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const classes = useStyles();
  const popperRef = useRef<HTMLButtonElement>(null);
  const [hoveredIcon, setHoveredIcon] = useState<string>("");
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [stopClickAway, setStopClickAway] = useState<boolean>(false);

  const { i18n, t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { userData } = useProfileData();
  const { setUtagCustomer } = useUtag();

  // const name = useMemo(() => userData?.firstName, [userData?.firstName]);

  const displayCountdownHeader = false;
  const renderCountdownHeader = null;

  const handleIconHover = useCallback((type: string, hover: boolean) => {
    setHoveredIcon(hover ? type : "");
  }, []);

  const [activePopper, setActivePopper] = useState<ActivePopperType>("");

  // const isNeedUpdate = getData("BASF-isNeedUpdate");

  // const openProfilePopper = useMemo(
  //   () => !!anchorEl && activePopper === "profile",
  //   [activePopper, anchorEl]
  // );
  //
  // const openLanguagePopper = useMemo(
  //   () => !!anchorEl && activePopper === "languages",
  //   [activePopper, anchorEl]
  // );
  //
  // const openFavoritesPopper = useMemo(
  //   () => !!anchorEl && activePopper === "favorites",
  //   [activePopper, anchorEl]
  // );
  //
  // const openMyBagPopper = useMemo(
  //   () => !!anchorEl && activePopper === "bag",
  //   [activePopper, anchorEl]
  // );

  const handleIconClick = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLSpanElement, MouseEvent>,
      type: ActivePopperType
    ) => {
      if (!!popperRef?.current) {
        event.preventDefault();
        event.stopPropagation();

        if (anchorEl && type === activePopper) {
          isMobile && enableBodyScroll();
          setAnchorEl(null);
          setActivePopper("");
        } else if (
          (!!type && type !== activePopper) ||
          (!isClosed && type === activePopper)
        ) {
          if (isMobile) disableBodyScroll();
          const newAnchor =
            type === "languages"
              ? (event as React.MouseEvent<HTMLButtonElement>).currentTarget
              : popperRef?.current;
          setAnchorEl(newAnchor);
          setActivePopper(type);
          setIsClosed(false);
          openMobileMenu && setOpenMobileMenu(false);
        } else {
          setIsClosed(false);
        }
      }
    },
    [activePopper, anchorEl, isClosed, isMobile, popperRef, openMobileMenu]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
    openMobileMenu && !stopClickAway && setOpenMobileMenu(false);
    enableBodyScroll();
    setIsClosed(true);
    setStopClickAway(false);
  }, [openMobileMenu, stopClickAway]);

  // const toggleMobileMenu = useCallback(() => {
  //   if (!openMobileMenu) {
  //     setAnchorEl(null);
  //     disableBodyScroll();
  //     setActivePopper("");
  //   } else enableBodyScroll();
  //   setOpenMobileMenu((prev) => !prev);
  //   setStopClickAway(true);
  // }, [openMobileMenu]);

  const closeExtras = useCallback(() => {
    if (!!anchorEl && !!activePopper) {
      setAnchorEl(null);
      setActivePopper("");
    }
  }, [activePopper, anchorEl]);

  // useEffect(() => {
  //   if (showCartPopper) {
  //     setAnchorEl(popperRef?.current);
  //     setActivePopper("bag");
  //     disableBodyScroll();
  //   }
  // }, [showCartPopper, onClose]);

  useEffect(() => {
    if (isLoggedIn && userData) {
      setUtagCustomer({
        customer_id: String(userData?.id),
        customer_email: userData?.email,
        customer_country: i18n?.language,
        customer_region: "europe",
        customer_city: userData?.city,
        customer_zip: userData?.postalCode,
        customer_organization: userData?.companyName,
      });
    }
  }, [i18n.language, isLoggedIn, setUtagCustomer, userData]);

  return (
    <>
      <Box
        className={clsx(classes.appbarFill, {
          [classes.appbarFillTop]: !isLoggedIn,
          [classes.appbarFillWTimer]: displayCountdownHeader,
        })}
      ></Box>
      <AppBar color="inherit" elevation={0} position="fixed">
        {renderCountdownHeader}
        <Top
          handleIconClick={handleIconClick}
          handleIconHover={handleIconHover}
          activePopper={activePopper}
          hoveredIcon={hoveredIcon}
          onClose={onClose}
          anchorEl={anchorEl}
          growMenu={growMenu}
          closeExtras={closeExtras}
          popperRef={popperRef}
          openMobileMenu={openMobileMenu}
          userData={userData}
          t={t}
        />
        {isLoggedIn && (
          <Hidden mdUp>
            <Grid
              className={classes.pointsMobileContainer}
              container
              alignItems="center"
            >
              <Grid item>
                <ProfilePopper
                  isLoggedIn={isLoggedIn}
                  handleIconClick={handleIconClick}
                  handleIconHover={handleIconHover}
                  activePopper={activePopper}
                  anchorEl={anchorEl}
                  popperRef={popperRef}
                  t={t}
                  onClose={onClose}
                />
              </Grid>
              <Grid item>
                <Points />
              </Grid>
            </Grid>
          </Hidden>
        )}
        {/*<Collapse in={growMenu} timeout={1100}>*/}
        {/*  <Toolbar classes={{ root: classes.toolbar }}>*/}
        {/*    <Container disableGutters className={classes.fullHeight}>*/}
        {/*      <Grid*/}
        {/*        justifyContent="space-between"*/}
        {/*        alignItems="center"*/}
        {/*        container*/}
        {/*        className={classes.fullHeight}*/}
        {/*      >*/}
        {/*        <Grid item className={classes.menuGrid}>*/}
        {/*          {isLoggedIn && (*/}
        {/*            <>*/}
        {/*              <Hidden smDown>*/}
        {/*                <NavMenu closeExtras={closeExtras} />*/}
        {/*              </Hidden>*/}
        {/*              <Hidden mdUp>*/}
        {/*                <MobileNavMenu*/}
        {/*                  openMobileMenu={openMobileMenu}*/}
        {/*                  toggleMobileMenu={toggleMobileMenu}*/}
        {/*                  onClose={onClose}*/}
        {/*                />*/}
        {/*              </Hidden>*/}
        {/*            </>*/}
        {/*          )}*/}
        {/*        </Grid>*/}
        {/*        <Grid item>*/}
        {/*          <Grid justifyContent="flex-end" alignItems="center" container>*/}
        {/*            {isLoggedIn && (*/}
        {/*              <Hidden smDown>*/}
        {/*                <Grid item>*/}
        {/*                  <Points />*/}
        {/*                </Grid>*/}
        {/*              </Hidden>*/}
        {/*            )}*/}
        {/*            <Box className={classes.poppersContent}>*/}
        {/*              {isLoggedIn && (*/}
        {/*                <Box*/}
        {/*                  className={classes.profileBox}*/}
        {/*                  onClick={(e) => handleIconClick(e, "profile")}*/}
        {/*                  onMouseEnter={() => handleIconHover("profile", true)}*/}
        {/*                  onMouseLeave={() => handleIconHover("profile", false)}*/}
        {/*                >*/}
        {/*                  {!isXS && (*/}
        {/*                    <Typography*/}
        {/*                      variant="h5"*/}
        {/*                      color="primary"*/}
        {/*                      className={classes.profileName}*/}
        {/*                    >*/}
        {/*                      {name}*/}
        {/*                    </Typography>*/}
        {/*                  )}*/}
        {/*                  <ActionIcon*/}
        {/*                    isActive={*/}
        {/*                      (activePopper === "profile" &&*/}
        {/*                        openProfilePopper) ||*/}
        {/*                      hoveredIcon === "profile"*/}
        {/*                    }*/}
        {/*                    type={"user"}*/}
        {/*                    handleIconClick={(e) =>*/}
        {/*                      handleIconClick(e, "profile")*/}
        {/*                    }*/}
        {/*                    handleHover={() => {}}*/}
        {/*                    badge={false}*/}
        {/*                    noSpacing*/}
        {/*                  />*/}
        {/*                </Box>*/}
        {/*              )}*/}
        {/*              <IconButton*/}
        {/*                id="popper-ref"*/}
        {/*                ref={popperRef}*/}
        {/*                style={{ padding: "24px 0" }}*/}
        {/*              />*/}
        {/*            </Box>*/}
        {/*          </Grid>*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*    </Container>*/}
        {/*  </Toolbar>*/}
        {/*</Collapse>*/}
        {/*{isLoggedIn && (*/}
        {/*  <>*/}
        {/*    <HeaderPopper*/}
        {/*      open={openMyBagPopper}*/}
        {/*      anchorEl={anchorEl}*/}
        {/*      onBackdropClick={onClose}*/}
        {/*      key={"my-bag-popper"}*/}
        {/*    >*/}
        {/*      <MyBagList translationKey={activePopper} onClose={onClose} />*/}
        {/*    </HeaderPopper>*/}
        {/*    <HeaderPopper*/}
        {/*      open={openFavoritesPopper}*/}
        {/*      anchorEl={anchorEl}*/}
        {/*      onBackdropClick={onClose}*/}
        {/*      key={"favorites-popper"}*/}
        {/*    >*/}
        {/*      <FavoritesList translationKey={activePopper} onClose={onClose} />*/}
        {/*    </HeaderPopper>*/}
        {/*    <HeaderPopper*/}
        {/*      open={openProfilePopper}*/}
        {/*      anchorEl={anchorEl}*/}
        {/*      onBackdropClick={onClose}*/}
        {/*      key={"profile-popper"}*/}
        {/*    >*/}
        {/*      <ProfileList translationKey={"profile"} onClose={onClose} />*/}
        {/*    </HeaderPopper>*/}
        {/*  </>*/}
        {/*)}*/}
        {anchorEl && (
          <Hidden smUp>
            <Divider />
          </Hidden>
        )}
      </AppBar>
    </>
  );
};
export default Header;

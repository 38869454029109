import { useMemo } from "react";
import { Link } from "../types/Common";
import { usePaths } from "../constants/routes";
import { useProfileBriefInfo } from "../state/Profile";

export default function useLinksUtil() {
  const { isBasfUser } = useProfileBriefInfo();
  const paths = usePaths();

  const navLinks = useMemo(
    (): Link[] => [
      {
        key: "dashboard",
        path: paths.home,
      },
    ],
    [paths.home]
  );

  const distributorNavLinks = useMemo(
    (): Link[] => [
      {
        key: "dashboard",
        path: paths.home,
      },
    ],
    [paths.home]
  );

  return {
    navLinks: (isBasfUser ? navLinks : distributorNavLinks) ?? [],
  };
}

export const useProfileNavLinks = (): Array<{
  key: string;
  path: string;
  action?: string;
}> => {
  const paths = usePaths();
  const { isBasfUser } = useProfileBriefInfo();

  const basfUserProfileLinks = useMemo(
    () => [
      { key: "header.my_profile", path: paths.profile },
      { key: "header.application_form", path: paths.applicationForm },
      { key: "common.my_invoices", path: `${paths.invoice}#my_invoices` },
      { key: "common.my_points_history", path: paths.myPointsHistory },
      { key: "header.change_password", path: paths.changePassword },
      { key: "header.logout", path: paths.login, action: "logout" },
    ],
    [
      paths.changePassword,
      paths.login,
      paths.myPointsHistory,
      paths.profile,
      paths.applicationForm,
      paths.invoice,
    ]
  );

  const distributorProfileLinks = useMemo(
    () => [
      { key: "header.my_profile", path: paths.profile },
      { key: "common.my_points_history", path: paths.myPointsHistory },
      { key: "header.change_password", path: paths.changePassword },
      { key: "header.logout", path: paths.login, action: "logout" },
    ],
    [paths.changePassword, paths.login, paths.myPointsHistory, paths.profile]
  );

  return isBasfUser ? basfUserProfileLinks : distributorProfileLinks;
};

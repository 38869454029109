export enum Colors {
  darkBlue = "#004A96",
  basfBlue = "#21A0D2",
  lightBlue = "#6DA4D0",
  badgeBlue = "#00A3D3",
  greenButton = "#00793A",
  greenTable = "#185224",
  greenText = "#65AC1E",
  tableBackground = "#162118",
  yellow = "#F6BF31",
  greenBubble = "#2E3C31",
  smallTableBack = "#F4FFEB",
  paleRed = "#F8E0E4",
  basfRed = "#C50022",
  paleLightGreen = "#E0EED2",
  basfDarkGreen = "#00793A",
  paleOrange = "#FFEFD9",
  basfOrange = "#F39500",
  paleLightBlue = "#E0F2F9",
  paleLightBlueAlt = "#D8E6F4",
  paleDarkBlue = "#E0E9F2",
  copyTextGrey = "#333333",
  darkGrey = "#050505",
  middleGrey = "#ADADAD",
  backgroundBlue = "#EEF3F8",
  lightGrey = "#DCDCDC",
  boxGrey = "#F0F0F0",
  white = "#FFFFFF",
  cardDivider = "rgba(189, 189, 189, 0.4)",
  lightGreyDivider = "rgba(220, 220, 220, 0.7)",
  bgGrey = "rgba(240, 240, 240, 0.8)",
  buttonBlue = "#0775B8",
  subtitleGrey = "#7C7C7C",
  linearBar = "linear-gradient(90deg, #00793A 3.61%, rgba(0, 121, 58, 0) 159.72%, rgba(0, 121, 58, 0.71) 159.72%)",
  whiteOverlay = "rgba(255,255,255, 0.5)",
  chipBorder = "rgba(0, 74, 150, 0.6)",
  lightBlueTransparent = "rgba(224, 233, 242, 0.30)",
  lightBlueTransparentAlt = "rgba(238, 243, 248, 0.40)",
  blueTransparent = "rgba(0, 74, 150, 0.05)",
  lightGreyAlt = "#7E99B4",
  lightRedTransparent = "rgba(248, 224, 228, 0.30)",
  lightRedTransparentAlt = "rgba(248, 224, 228, 0.40)",
  redTransparent = "rgba(197, 0, 34, 0.05)",
  redTransparentAlt = "rgba(93, 25, 25, 0.60)",
  greyTransparent = "rgba(224, 233, 242, 0.60)",
  cultured = "#F4F5F6",
}

export enum Shadows {
  ordersList = "-2px 0px 4px rgba(0, 0, 0, 0.03), 2px 2px 4px rgba(0, 0, 0, 0.03)",
  mobileMenu = "0px 6px 10px rgba(0, 0, 0, 0.02)",
}

import { useMemo, useState, useCallback, useEffect } from "react";
import useSWR from "swr";
import { Endpoints } from "../api/endpoints";
import { MyPointsHistoryListResponse } from "../types/Points";

export const usePointsHistory = () => {
  const [page, setPage] = useState<number>(1);
  const { data: pointsHistoryResponse, error } =
    useSWR<MyPointsHistoryListResponse>(`${Endpoints.pointsHistory}`);

  const pointsLoading = useMemo(
    () => !pointsHistoryResponse && !error,
    [error, pointsHistoryResponse]
  );

  const pointsHistory = useMemo(
    () => pointsHistoryResponse?.points,
    [pointsHistoryResponse?.points]
  );

  const pointsBalance = useMemo(
    () => pointsHistoryResponse?.balance,
    [pointsHistoryResponse?.balance]
  );

  const expiringPoints = useMemo(
    () => pointsHistoryResponse?.expiringPoints,
    [pointsHistoryResponse?.expiringPoints]
  );

  const currentPage = useMemo(
    () => pointsHistoryResponse?.paginationModel?.currentPageNumber,
    [pointsHistoryResponse?.paginationModel?.currentPageNumber]
  );

  const totalPages = useMemo(
    () => pointsHistoryResponse?.paginationModel?.totalPages,
    [pointsHistoryResponse?.paginationModel?.totalPages]
  );

  const rewardsHistory = useMemo(
    () => pointsHistoryResponse?.rewards,
    [pointsHistoryResponse?.rewards]
  );

  const onPageChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  useEffect(() => {
    if (typeof currentPage === "number" && currentPage !== page) {
      setPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return {
    myPointsHistory: pointsHistory,
    loading: pointsLoading,
    totalPages,
    onPageChange,
    page,
    pointsBalance,
    expiringPoints,
    rewardsHistory,
  };
};

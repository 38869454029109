import { Box, Grid, Typography } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useProfileBriefInfo } from "../../../state/Profile";
import Loader from "../../Loader/Loader";
import { useStyles } from "./Points.styles";
import { useHistory } from "react-router-dom";
import { usePaths } from "../../../constants/routes";
import { ReactComponent as ExclamationIcon } from "../../../assets/icons/exclamation.svg";
import { usePointsHistory } from "../../../state/Points";
// import ArrowRightIcon from "../../ArrowIcon/ArrowIcon";
const Points = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const paths = usePaths();
  const { profile, loading } = useProfileBriefInfo();
  const { expiringPoints } = usePointsHistory();

  const displayIcon = !!expiringPoints;

  const onPointsClick = useCallback(() => {
    history.push(paths.myPointsHistory);
  }, [history, paths.myPointsHistory]);

  const points = useMemo(() => profile?.accountBalance ?? 0, [profile]);

  return useMemo(() => {
    if (loading && !profile) return <Loader />;

    return (
      <Box className={classes.root} onClick={onPointsClick}>
        <Typography>{t("common.points_on_my_account")}:</Typography>
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="h5" className={classes.points}>
            {points.toLocaleString("de-CH")}
          </Typography>
          {displayIcon && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.icon}
            >
              <ExclamationIcon />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }, [
    loading,
    profile,
    classes.root,
    classes.points,
    classes.icon,
    t,
    points,
    displayIcon,
    onPointsClick,
  ]);
};
export default Points;

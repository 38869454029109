import {
  Button,
  Collapse,
  Container,
  Grid,
  Hidden,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStyles } from "./TopHeader.styles";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { Link, useHistory } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { useCallback, useMemo } from "react";
import { useAuth } from "../../../state/Auth";
import HeaderPopper from "../HeaderPopper/HeaderPopper";
import { ActivePopperType } from "../../../types/Components";
import { usePaths } from "../../../constants/routes";
import { useProfileBriefInfo } from "../../../state/Profile";
import Points from "../Points/Points";
import MyBagList from "../MyBagList/MyBagList";
import FavoritesList from "../FavoritesList/FavoritesList";
import { UserData } from "../../../types/Profile";
import ProfilePopper from "../ProfilePopper/ProfilePopper";

interface Props {
  handleIconHover: (type: string, hover: boolean) => void;
  activePopper: string;
  hoveredIcon: string;
  onClose: () => void;
  openMobileMenu: boolean;
  closeExtras: () => void;
  popperRef: any;
  handleIconClick: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    type: ActivePopperType
  ) => void;
  anchorEl: any;
  growMenu: boolean;
  userData: UserData;
  t: any;
}

const Top = ({
  handleIconHover,
  activePopper,
  onClose,
  handleIconClick,
  anchorEl,
  growMenu,
  popperRef,
  t,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { isLoggedIn } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const paths = usePaths();
  const { isBasfUser } = useProfileBriefInfo();

  const onHomeRedirect = useCallback(() => {
    history.push(isLoggedIn ? paths.home : paths.landing);
  }, [history, paths, isLoggedIn]);

  const onInvoiceGet = useCallback(() => {
    history.push(paths.invoice);
  }, [history, paths.invoice]);

  // const openProfilePopper = useMemo(
  //   () => !!anchorEl && activePopper === "profile",
  //   [activePopper, anchorEl]
  // );

  // const openLanguagePopper = useMemo(
  //   () => !!anchorEl && activePopper === "languages",
  //   [activePopper, anchorEl]
  // );

  const openFavoritesPopper = useMemo(
    () => !!anchorEl && activePopper === "favorites",
    [activePopper, anchorEl]
  );

  const openMyBagPopper = useMemo(
    () => !!anchorEl && activePopper === "bag",
    [activePopper, anchorEl]
  );

  return (
    <Toolbar
      className={classes.root}
      style={{
        zIndex: !isLoggedIn ? undefined : 10,
      }}
    >
      <Container disableGutters>
        <Grid justifyContent="space-between" alignItems="center" container>
          <Logo className={classes.icon} onClick={onHomeRedirect} />
          {!isMobile && (
            <Link to={paths.landing} className={classes.homeButton}>
              {t("header.home_button")}
            </Link>
          )}
          {isLoggedIn && isBasfUser && (
            <>
              <Collapse in={growMenu} timeout={1100}>
                <Grid item>
                  <Grid justifyContent="flex-end" alignItems="center" container>
                    <Hidden smDown>
                      <ProfilePopper
                        isLoggedIn={isLoggedIn}
                        handleIconClick={handleIconClick}
                        handleIconHover={handleIconHover}
                        activePopper={activePopper}
                        anchorEl={anchorEl}
                        popperRef={popperRef}
                        t={t}
                        onClose={onClose}
                      />
                    </Hidden>
                    {isLoggedIn && (
                      <>
                        <Hidden smDown>
                          <Grid item>
                            <Points />
                          </Grid>
                        </Hidden>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={isMobile ? undefined : <Add />}
                          className={classes.btn}
                          onClick={onInvoiceGet}
                        >
                          {t("buttons.add_invoice")}
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Collapse>
              {isLoggedIn && (
                <>
                  <HeaderPopper
                    open={openMyBagPopper}
                    anchorEl={anchorEl}
                    onBackdropClick={onClose}
                    key={"my-bag-popper"}
                  >
                    <MyBagList
                      translationKey={activePopper}
                      onClose={onClose}
                    />
                  </HeaderPopper>
                  <HeaderPopper
                    open={openFavoritesPopper}
                    anchorEl={anchorEl}
                    onBackdropClick={onClose}
                    key={"favorites-popper"}
                  >
                    <FavoritesList
                      translationKey={activePopper}
                      onClose={onClose}
                    />
                  </HeaderPopper>
                  {/* <HeaderPopper
                    open={openProfilePopper}
                    anchorEl={anchorEl}
                    onBackdropClick={onClose}
                    key={"profile-popper"}
                  >
                    <ProfileList translationKey={"profile"} onClose={onClose} />
                  </HeaderPopper> */}
                </>
              )}
            </>
          )}
          {/*{!isLoggedIn && (*/}
          {/*  <>*/}
          {/*    <ActionIcon*/}
          {/*      isActive={*/}
          {/*        (activePopper === "languages" && openLanguagePopper) ||*/}
          {/*        hoveredIcon === "languages"*/}
          {/*      }*/}
          {/*      type={"languages"}*/}
          {/*      handleIconClick={(e) => handleIconClick(e, "languages")}*/}
          {/*      handleHover={(hover) => handleIconHover("languages", hover)}*/}
          {/*      badge={false}*/}
          {/*    />*/}
          {/*    <HeaderPopper*/}
          {/*      open={openLanguagePopper}*/}
          {/*      anchorEl={anchorEl}*/}
          {/*      onBackdropClick={onClose}*/}
          {/*      key="languages-popper"*/}
          {/*    >*/}
          {/*      <LanguagesList translationKey={"languages"} onClose={onClose} />*/}
          {/*    </HeaderPopper>*/}
          {/*  </>*/}
          {/*)}*/}
        </Grid>
      </Container>
    </Toolbar>
  );
};

export default Top;

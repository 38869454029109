import { Box, IconButton, Typography } from "@material-ui/core";
import { useMemo, FC } from "react";
import { ActivePopperType } from "../../../types/Components";
import { useStyles } from "../TopHeader/TopHeader.styles";
import ArrowRotateIcon from "../../ArrowIcon/ArrowIcon";
import HeaderPopper from "../HeaderPopper/HeaderPopper";
import ProfileList from "../ProfileList/ProfileList";

interface IProfilePopperProps {
  isLoggedIn: boolean;
  handleIconHover: (type: string, hover: boolean) => void;
  handleIconClick: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    type: ActivePopperType
  ) => void;
  activePopper: string;
  anchorEl: any;
  popperRef: any;
  t: any;
  onClose: () => void;
}
const ProfilePopper: FC<IProfilePopperProps> = ({
  isLoggedIn,
  handleIconClick,
  handleIconHover,
  activePopper,
  anchorEl,
  popperRef,
  t,
  onClose,
}) => {
  const classes = useStyles();

  const openProfilePopper = useMemo(
    () => !!anchorEl && activePopper === "profile",
    [activePopper, anchorEl]
  );

  return (
    <Box className={classes.poppersContent}>
      {isLoggedIn && (
        <Box
          className={classes.profileBox}
          onClick={(e) => handleIconClick(e, "profile")}
          onMouseEnter={() => handleIconHover("profile", true)}
          onMouseLeave={() => handleIconHover("profile", false)}
        >
          <Typography
            variant="h5"
            className={classes.profileName}
            onClick={(e) => handleIconClick(e, "profile")}
          >
            {t("menu.profile")}
            <Box display={"inline-flex"} marginLeft={"8px"}>
              <ArrowRotateIcon
                open={openProfilePopper}
                isBlue={false}
                isWhite={true}
              />
            </Box>
          </Typography>
        </Box>
      )}
      <IconButton
        id="popper-ref"
        ref={popperRef}
        style={{ padding: "16px 0" }}
      />
      <HeaderPopper
        open={openProfilePopper}
        anchorEl={anchorEl}
        onBackdropClick={onClose}
        key={"profile-popper"}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ]}
      >
        <ProfileList translationKey={"profile"} onClose={onClose} />
      </HeaderPopper>
    </Box>
  );
};
export default ProfilePopper;
